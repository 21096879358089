<template>
    <section class="pedidos-internos-ordenes-compra">
        <titulo-divisor titulo="Ordenes de compra">
            <div class="row">
                <div class="col-auto my-auto px-1 icon-option">
                    <el-tooltip content="Agregar orden de compra" visible-arrow effect="light">
                        <i class="icon-plus-circle  f-30"
                            :class="permitAction('almacen.pedido.agregar.orden') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.pedido.agregar.orden', addOrdenCompra)"
                        />
                    </el-tooltip>
                </div>
                <div class="col auto my-auto">
                    <el-input v-model="buscarListado" placeholder="Buscar pedido" size="small" @keypress.native.enter="filtrar" />
                </div>
                <filter-indicator :count="filterCount" @openModalFilter="abrirModalFiltros" @clearFilter="limpiarFiltro"/>
                
            </div>
        </titulo-divisor>
        <!-- tabla -->
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :data="ordenesCompra">
            <el-table-column label="Orden" prop="orden" width="80" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2  f-600"> {{ scope.row.id }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Proyecto" prop="proyecto" show-overflow-tooltip>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos cr-pointer" @click="verOC(scope.row.id)"> {{ scope.row.proyecto }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="170">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600"> {{ scope.row.tipo }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Estado" prop="estado">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 d-middle font-italic">
                        <i v-if="scope.row.estado" class="icon-circle-check f-18" style="color:#3CD273" />
                        <i v-else class="icon-history f-20" style="color:#F96F28" />
                        {{ setEstadoCompra(scope.row.estado) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valor" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted"> {{ formatoMoneda(scope.row.valor) }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Responsable entrega" prop="responsable">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2"> {{ scope.row.responsable }} </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-3">
                    <p class="input-label-top">Orden de compra</p>
                    <el-select v-model="filter.id_orden_compra" clearable filterable placeholder="Seleccionar solicitante" size="small" class="w-100">
                        <el-option v-for="item in filtroOrdenesCompra.orden_compra" :key="item.id" :label="item.id" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Proyecto</p>
                    <el-select v-model="filter.id_proyecto" clearable filterable placeholder="Seleccionar proyecto" size="small" class="w-100">
                        <el-option v-for="item in filtroOrdenesCompra.proyectos" :key="item.id" :label="item.proyecto" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Estado</p>
                    <el-select v-model="filter.estado" clearable filterable placeholder="Seleccionar equipo" size="small" class="w-100">
                        <el-option v-for="item in filtroOrdenesCompra.estados" :key="item.id" :label="item.estado" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_valores" range :min="filtroOrdenesCompra.valor_min" :max="filtroOrdenesCompra.valor_max" :format-tooltip="formatTooltipRange" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrar"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-agregar-orden-compra ref="modalAgregarOrdenCompra" />
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import modalAgregarOrdenCompra from './partials/modalAgregarOrdenCompra.vue'
export default {
    name: 'ordenesCompra',
    components: {
        modalAgregarOrdenCompra,
    },
    mounted(){
        this.getBreadcumbs([ 'almacen.main', 'almacen.pedidos.internos', 'almacen.pedidos.internos.ordenes.compra' ]);
    },
    data() {
        return {
            buscarListado: '',
            options:[
                {
                    id: 1,
                    nombre: 'Option1'
                }
            ],
            range_value_cantidad:[0, 0],
            dataFilters:{
                id_solicitante: '',
                id_proyecto: '',
                id_equipo: '',
            },
            dataListadoPedidos:[
                {
                    id: 1,
                    orden: '45424',
                    proyecto: '23- Planta nueva Colombia',
                    tipo: 'Materiales',
                    estado: 0,
                    valor: 85000,
                    responsable: 'Porveedor'
                },
                {
                    id: 2,
                    orden: '32454',
                    proyecto: '56- Proyecto de la planta de leche',
                    tipo: 'Materiales',
                    estado: 1,
                    valor: 35000,
                    responsable: 'Bodega'
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            ordenesCompra: 'almacen/pedidosInternosOcs/ordenesCompra',
            filtroOrdenesCompra: 'almacen/pedidosInternosOcs/filtroOrdenesCompra',
            filtroOcs: 'almacen/pedidosInternosOcs/filtroOcs',
        }),
        filter: {
            get(){ return this.filtroOcs }, 
            set(val){ this.set_filtro_ocs(val) }
        },
        range_valores: {
            get(){
                return [
                    this.filter.valor_min,
                    this.filter.valor_max
                ]
            },
            set(val){
                this.filter.valor_min = val[0]
                this.filter.valor_max = val[1]
            }
        },
        filterCount(){
            return this.filter.count();
        },
    },
    async created(){
        await this.Action_get_filtros_ordenes_compras()
        await this.limpiarFiltro()
        await this.filtrar()
    },
    methods: {
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_get_ocs_pedidos_internos: 'almacen/pedidosInternosOcs/Action_get_ocs_pedidos_internos',
            Action_get_filtros_ordenes_compras: 'almacen/pedidosInternosOcs/Action_get_filtros_ordenes_compras',
            Action_clear_filter_ocs: 'almacen/pedidosInternosOcs/Action_clear_filter_ocs',
        }),

        ...mapMutations({
            set_filtro_ocs: 'almacen/pedidosInternosOcs/set_filtro_ocs',
        }),

        setEstadoCompra(estado){
            return estado ? 'Entrega completa' : 'Pendiente'
        },
        addOrdenCompra() {
            this.$refs.modalAgregarOrdenCompra.toggle()
        },
        abrirModalFiltros() {
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async limpiarFiltro() {
            this.Action_clear_filter_ocs()
            await this.Action_get_ocs_pedidos_internos()
        },
        verOC(id) { 
            this.$router.push({ name: 'almacen.pedidos.ver.orden.compra', params: { id: id } })
        },
        async  filtrar() {
            await this.Action_get_ocs_pedidos_internos({buscar:this.buscarListado})
        }
    },
    

}
</script>

<style>

</style>