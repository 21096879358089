<template>
    <modal ref="modalAddOC" titulo="Agregar materiales de la orden de compra" tamano="modal-lg" adicional="Agregar" @adicional="createOrder">
        <ValidationObserver ref="validator">
            <div class="row my-3 mx-0 f-14">
                <div class="col-12 my-2">
                    <p class="f-12 mb-1">Seleccione el responsable de la entrega</p>
                    <el-radio v-model="radioResponsable" label="1" @change="cleanSelects">Proveedor</el-radio>
                    <el-radio v-model="radioResponsable" label="0" @change="cleanSelects">Bodega</el-radio>
                </div>
                <div v-if="radioResponsable == 1" class="col-12 my-2">
                    <p class="input-label-top">Proveedor</p>
                    <ValidationProvider name="proveedor" v-slot="{ errors }" rules="required">
                        <el-select v-model="model.id_proveedor" filterable placeholder="Seleccionar proveedor" size="small" class="w-100">
                            <el-option v-for="item in select_providers" :key="item.id" :label="item.proveedor" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div v-else class="col-12 my-2">
                    <p class="input-label-top">Bodega</p>
                    <ValidationProvider name="bodega" v-slot="{ errors }" rules="required">
                        <el-select v-model="model.id_bodega" filterable placeholder="Seleccionar bodega" size="small" class="w-100">
                            <el-option v-for="item in select_wineries" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Pedido</p>
                    <ValidationProvider name="pedido" v-slot="{ errors }" rules="required">
                        <el-select v-model="model.id_pedido" filterable placeholder="Seleccionar pedido" size="small" class="w-100" @change="listarMateriales">
                            <el-option v-for="item in pedidos" :key="item.id" :label="item.pedido" :value="item.id"/>
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Materiales</p>
                    <ValidationProvider name="materiales" v-slot="{ errors }" rules="required">
                        <el-select v-model="model.materiales" multiple filterable placeholder="Seleccionar materiales" size="small" class="w-100">
                            <el-option v-for="item in pedidos_internos_materiales" :key="item.id" :label="item.material" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            model:{
                id_proveedor:null,
                id_bodega:null,
                id_pedido:'',
                materiales:[],
                tipo:''
            },
            radioResponsable: '1',
        }
    },  
    computed: {
        ...mapGetters({
            select_providers: 'selects/selects/select_providers',
            select_wineries: 'selects/selects/select_wineries',
            pedidos: 'almacen/pedidosInternosOcs/pedidos',
            pedidos_internos_materiales:'almacen/pedidosInternosOcs/pedidos_internos_materiales',
        }),
    },
    async created(){
        await this.Action_get_select_providers()
        await this.Action_get_select_wineries()
        await this.Action_get_ocs_pedidos()
    },
    methods: {
        ...mapActions({
            Action_get_select_providers: 'selects/selects/Action_get_select_providers',
            Action_get_select_wineries: 'selects/selects/Action_get_select_wineries',
            Action_get_ocs_pedidos: 'almacen/pedidosInternosOcs/Action_get_ocs_pedidos',
            Action_get_materiales_pedidos_internos: 'almacen/pedidosInternosOcs/Action_get_materiales_pedidos_internos',
            Action_post_materiales_pedidos_internos: 'almacen/pedidosInternosOcs/Action_post_materiales_pedidos_internos',
            Action_informacion_detalle_ocs: 'almacen/pedidosInternosOcs/Action_informacion_detalle_ocs',
            
        }),
        toggle(){
            this.$refs.modalAddOC.toggle()
        },
        async listarMateriales(id_pedido){
            await this.Action_get_materiales_pedidos_internos({id_pedido_interno:id_pedido})
        },
        async getMaterials(id_categoria){
            console.log('id_categoria=>',id_categoria);
            //await this.Action_get_materials({id_categoria})
        },
        async getEspesores(id_material){
            console.log('id_material=>',id_material);
            //await this.Action_get_espesores({id_material})
        },
        async createOrder(){
            const valid = await this.$refs.validator.validate()
            if (!valid) return 
            this.model.tipo = this.radioResponsable
            localStorage.removeItem('detalleOcs');
            this.Action_informacion_detalle_ocs(this.model)
            this.$refs.modalAddOC.toggle()
            if (this.radioResponsable == 1) {
                this.$router.push({ name: 'almacen.pedidos.internos.oc.materiales.proveedor' })
            }
            if (this.radioResponsable == 0) {
                this.$router.push({ name: 'almacen.pedidos.internos.oc.materiales.bodega' })
            }
        },
        cleanSelects(){
            this.model.id_proveedor = null,
            this.model.id_bodega = null,
            this.model.id_pedido = '',
            this.model.materiales = []
        }
    }
}
</script>

<style lang="css" scoped>
</style>
